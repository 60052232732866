module.exports = {
  // App Info
  appstore_link         : "https://imedadel.me",                                  // Enter App Store URL.
  playstore_link        : "https://imedadel.me",                                  // Enter Google Play Store URL.
  google_analytics_ID   : "UA-47311644-5",                                        // Enter Google Analytics ID or ""
  presskit_download_link: "https://imedadel.me",                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://agalp.imedadel.me",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Dinner People",
  app_price             : "Free",
  app_description       : "An app for people to do dinner together",
  app_keywords          : ["social", "friends", "dinner", "landing page"],

  // Personal Info
  your_name              : "Brian",
  your_link              : "https://dinnerpeople.app",
  your_city              : "sweatpants",
  email_address          : "hello@briandouglas.me",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : "dinnerpeopleapp",
  twitter_username       : "dinnerpeopleapp",
  github_username        : "bdougie",
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Gather with friends",
      description: "Whether you are making new friends or gathering exising it can't get easier with Dinner People",
      fontawesome_icon_name: "bacon",
    },
    {
      title: "Schedule Dinners",
      description:
        "The latest in machine learning can be leveraged to find the best time for your next  of first dinner party",
      fontawesome_icon_name: "utensils",
    },
    {
      title: "Build Community through fun",
      description:
        "Each group is encourage to try need recipes and get kudos to unlock more features through the app",
      fontawesome_icon_name: "utensils",
    },
    {
      title: "Cooking is better with friends",
      description:
        "Easily assign roles for each member of the the party through the app. Dinner People is will choose the best role based on each members skillset.",
      fontawesome_icon_name: "cheese",
    },
    {
      title: "Integrates with iMessage",
      description:
        "Integrate with the latest iOS messaging features through the app or entirely via text messages. This ensures all people can stay up to date with your dinner plans",
      fontawesome_icon_name: "comment-dots",
    },
  ],
  header_background             : "rgba(0, 0, 0, 0.1)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
